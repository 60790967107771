import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Container, Typography, Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import backgroundStart from '../assets/background-start.jpg';

const Start = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left',
        py: 10,
        bgcolor: '#ffffff',
        color: 'black',
        padding: '20vh 0vh 0vh 0vh'
      }}
    >
      <Container style={{ height: '80vh' }}>
        <Grid container spacing={4} alignItems="center">
          <Grid style={{ padding: '0px' }} item xs={12} md={6}>
            <Typography variant={isMobile ? 'h3' : 'h1'} component="h1" gutterBottom sx={{ fontSize: '45px', fontWeight: '800', mb: 4, color: '#F46A35' }}>
              A plataforma de gestão financeira por IA.
            </Typography>
            <Typography variant={isMobile ? 'h5' : 'h4'} component="p" gutterBottom sx={{ fontSize: '28px', fontWeight: '700', mb: 6 }}>
              Entenda os números da sua empresa com mais rapidez, clareza e inteligência.
              <br />
              O necessário para o seu negócio funcionar.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<FaArrowRight />}
                sx={{ bgcolor: 'white', color: 'black', px: 4, py: 1.5, borderRadius: 20, '&:hover': { bgcolor: 'lightgray' } }}
              >
                Comece Gratuito
              </Button>
              <Button
                variant="outlined"
                color="primary"
                endIcon={<FaArrowRight />}
                sx={{ bgcolor: '#F46A35', color: 'white', px: 4, py: 1.5, borderRadius: 20, '&:hover': { bgcolor: '#F46A35', color: 'black' } }}
              >
                Entre em Contato
              </Button>
            </Box>
          </Grid>
          <Grid style={{ userSelect: 'none', padding: '0px' }} item xs={12} md={6}>
            <img 
              src={backgroundStart} 
              alt="Spifex" 
              style={{ 
                width: '100%', 
                borderRadius: '20px', 
                pointerEvents: 'none', 
              }} 
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Start;
