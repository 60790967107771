import React, { useState } from 'react';

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);

  const preprogrammedResponses = {
    // Gerenciamento de Inventário
    "como registrar novos produtos no sistema?": "Para registrar novos produtos no sistema, siga os seguintes passos:\n\n1. Acesse a seção de inventário no menu principal.\n2. Clique em 'Adicionar Novo Produto'.\n3. Preencha os campos obrigatórios, como nome do produto, categoria, preço de custo, preço de venda e quantidade inicial em estoque.\n4. Clique em 'Salvar' para registrar o produto no sistema.",
    "como atualizar a quantidade de estoque de um produto?": "Para atualizar a quantidade de estoque, siga os seguintes passos:\n\n1. Acesse a seção de inventário no menu principal.\n2. Selecione o produto que deseja atualizar.\n3. Clique em 'Editar'.\n4. Atualize a quantidade de estoque no campo apropriado.\n5. Clique em 'Salvar' para aplicar as alterações.",
    "o que fazer quando um produto está esgotado?": "Quando um produto está esgotado, você pode:\n\n1. Definir o status do produto como 'Esgotado' no sistema.\n2. Enviar notificações para os clientes interessados sobre a indisponibilidade.\n3. Planejar o reabastecimento do estoque o mais rápido possível.",
    "como gerenciar produtos com datas de validade?": "Para gerenciar produtos com datas de validade, siga os seguintes passos:\n\n1. Registre a data de validade ao adicionar ou atualizar um produto.\n2. Configure alertas para avisar quando a data de validade estiver próxima.\n3. Realize verificações periódicas e remova produtos expirados do estoque.",
    
    // Desempenho do Inventário
    "como analisar o desempenho de vendas de um produto específico?": "Para analisar o desempenho de vendas de um produto específico, siga os seguintes passos:\n\n1. Acesse a seção de relatórios no menu principal.\n2. Selecione 'Relatório de Vendas'.\n3. Filtre os resultados pelo produto específico que deseja analisar.\n4. Visualize os dados de vendas, incluindo quantidade vendida, receita gerada e comparações com períodos anteriores.",
    
    // Fluxo de Caixa
    "como registrar entradas e saídas de caixa?": "Para registrar entradas e saídas de caixa, siga os seguintes passos:\n\n1. Acesse a seção de finanças no menu principal.\n2. Clique em 'Registrar Transação'.\n3. Selecione se a transação é uma entrada ou saída de caixa.\n4. Preencha os detalhes da transação, como data, valor e descrição.\n5. Clique em 'Salvar' para registrar a transação.",
    
    // Relatórios e Análises
    "como criar relatórios personalizados?": "Para criar relatórios personalizados, siga os seguintes passos:\n\n1. Acesse a seção de relatórios no menu principal.\n2. Clique em 'Criar Relatório Personalizado'.\n3. Selecione os parâmetros desejados, como período, categorias e métricas.\n4. Clique em 'Gerar Relatório' para visualizar e salvar o relatório personalizado.",
    
    // Configuração do Sistema
    "como configurar usuários e permissões no sistema?": "Para configurar usuários e permissões no sistema, siga os seguintes passos:\n\n1. Acesse a seção de configurações no menu principal.\n2. Clique em 'Usuários e Permissões'.\n3. Adicione novos usuários e defina suas permissões conforme necessário.\n4. Clique em 'Salvar' para aplicar as configurações.",
  };

  const preprogrammedQuestions = [
    "como registrar novos produtos no sistema?",
    "como atualizar a quantidade de estoque de um produto?",
    "como registrar entradas e saídas de caixa?",
    "como criar relatórios personalizados?",
    "como configurar usuários e permissões no sistema?"
  ];

  const sendMessage = (messageText) => {
    if (messageText.trim() === '') return;

    const userMessage = { sender: 'user', text: messageText };
    setMessages([...messages, userMessage]);
    setInput('');

    const userMessageText = messageText.toLowerCase().trim();
    if (preprogrammedResponses[userMessageText]) {
      const responseText = preprogrammedResponses[userMessageText];
      typeMessage(responseText);
    } else {
      typeMessage("Desculpe, não tenho uma resposta para essa pergunta no momento.");
    }
  };

  const typeMessage = (text) => {
    setIsTyping(true);
    let index = 0;
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (lastMessage && lastMessage.sender === 'bot') {
          const newText = lastMessage.text + text[index];
          return [...prevMessages.slice(0, -1), { sender: 'bot', text: newText }];
        } else {
          return [...prevMessages, { sender: 'bot', text: text[index] }];
        }
      });
      index++;
      if (index === text.length) {
        clearInterval(interval);
        setIsTyping(false);
      }
    }, 50); // Velocidade de digitação
  };

  return (
    <div className="max-w-lg mx-auto my-8 p-4 bg-[#202020] text-white rounded-lg shadow-lg">
      <h2 className="text-center text-2xl font-bold mb-4">Converse com nossa I.A e veja como nosso APP pode Melhorar sua empresa</h2>
      <div className="h-64 overflow-y-auto mb-4">
        {messages.map((message, index) => (
          <div key={index} className={`mb-2 ${message.sender === 'user' ? 'text-right' : 'text-left'}`}>
            <span className={`inline-block p-2 rounded-lg ${message.sender === 'user' ? 'bg-blue-600' : 'bg-gray-700'}`}>
              {message.text}
            </span>
          </div>
        ))}
        {isTyping && (
          <div className="text-left">
            <span className="inline-block p-2 rounded-lg bg-gray-700">
              ...
            </span>
          </div>
        )}
      </div>
      <div className="flex mb-4">
        <input
          type="text"
          className="flex-grow p-2 rounded-l-lg border-none bg-gray-800 text-white focus:outline-none"
          placeholder="Digite sua mensagem..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage(input)}
        />
        <button onClick={() => sendMessage(input)} className="bg-[#F46A35] text-white p-2 rounded-r-lg hover:bg-[#ff7b50] transition duration-300">Enviar</button>
      </div>
      <div>
        <h3 className="text-lg font-bold mb-2">Perguntas Frequentes:</h3>
        <div className="flex flex-wrap">
          {preprogrammedQuestions.map((question, index) => (
            <button
              key={index}
              onClick={() => sendMessage(question)}
              className="bg-gray-700 text-white p-2 m-1 rounded-lg hover:bg-gray-600 transition duration-300"
            >
              {question}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
