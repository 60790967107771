import * as React from 'react';
import { Container, Grid, Card, CardContent, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const PricingCard = styled(Card)(({ theme, recommended }) => ({
  borderRadius: '10px',
  textAlign: 'center',
  padding: theme.spacing(3),
  backgroundColor: recommended ? theme.palette.primary.main : '#fff',
  color: recommended ? '#fff' : theme.palette.text.primary,
}));

const PricingTable = () => {
  const plans = [
    {
      title: 'Básico',
      price: 'R$59,90',
      period: 'por mês',
      features: ['2 usuários inclusos', 'Lançamentos ilimitados', 'Inteligência artificial básica', 'Módulo de gestão financeira'],
      buttonText: 'Comece grátis',
      buttonVariant: 'outlined',
    },
    {
      title: 'Profissional',
      price: 'R$79,90',
      period: 'por mês',
      features: ['5 usuários inclusos', 'Lançamentos ilimitados', 'Inteligência artificial intermediária', 'Módulo de gestão financeira', 'Módulo de projetos', 'Acesso a treinamentos'],
      buttonText: 'Comece grátis',
      buttonVariant: 'contained',
      recommended: true,
    },
    {
      title: 'Empresarial',
      price: 'R$99,90',
      period: 'por mês',
      features: ['Usuários ilimitados', 'Lançamentos ilimitados', 'Inteligência artificial mais avançada', 'Todos os módulos'],
      buttonText: 'Comece grátis',
      buttonVariant: 'outlined',
    },
  ];

  return (
    <Container style={{ padding:"50px 0px" }}>
      <Typography variant="h4" component="h1" align="center" sx={{ fontWeight: '800' }} gutterBottom>
        Preços
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ fontSize: '25px', fontWeight: '700' }} paragraph>
        A melhor decisão para você a partir de R$59,90
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {plans.map((plan) => (
          <Grid item key={plan.title} xs={12} sm={6} md={4} style={{ padding: '30px 20px' }}>
            <PricingCard recommended={plan.recommended}>
              <CardContent>
                <Typography variant="h5" component="h2" style={{ fontWeight: '700' }} gutterBottom>
                  {plan.title}
                </Typography>
                <Typography variant="h3" component="p" style={{ fontWeight: '700' }} gutterBottom>
                  {plan.price}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" component="p" gutterBottom>
                  {plan.period}
                </Typography>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {plan.features.map((feature) => (
                    <Typography component="li" variant="subtitle1" align="center" style={{ fontWeight: '500' }} key={feature}>
                      {feature}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <Box sx={{ mt: 2 }}>
                <Button variant={plan.buttonVariant} fullWidth>
                  {plan.buttonText}
                </Button>
              </Box>
            </PricingCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PricingTable;