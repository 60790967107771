import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FaBars } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/logo.png';

const Header = ({ scrollToPricing, scrollToTop }) => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenuItem(menuItem);
    handleMobileMenuClose();

    switch (menuItem) {
      case 'pricing':
        scrollToPricing();
        break;
      case 'aboutUs':
        navigate('/about');
        break;
      case 'top':
        scrollToTop();
        break;
      case 'login':
        window.location.href = 'https://dashboard.spifex.com/signin';
        break;
      default:
        break;
    }
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={() => handleMenuItemClick('values')}>Nossos Valores</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('aboutUs')}>Quem Somos</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('pricing')}>Preços</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('faq')}>Dúvidas</MenuItem>
      <MenuItem onClick={() => handleMenuItemClick('login')}>Entrar</MenuItem>
      <MenuItem onClick={() => window.location.href = 'https://dashboard.spifex.com/signup'}>
        <Button variant="contained" color="primary" style={{ borderRadius: '20px' }}>Cadastrar</Button>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar position="fixed" color="transparent" style={{ width: '80%', left: '50%', transform: 'translateX(-50%)', borderRadius: '50px', marginTop: '10px', backgroundColor: 'rgba(240, 240, 240, 0.8)', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
      <Toolbar>
        <img src={logo} alt="Spifex Logo" style={{ height: '40px', marginRight: '10px', cursor: 'pointer' }} onClick={() => handleMenuItemClick('top')} />
        <Typography variant="h6" component="div" sx={{ fontWeight: '500', flexGrow: 1, display: 'flex', alignItems: 'center', color: '#000', cursor: 'pointer' }} onClick={() => handleMenuItemClick('top')}>
          Spifex
        </Typography>
        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={handleMobileMenuOpen}>
              <FaBars style={{ color: '#000' }} />
            </IconButton>
            {renderMobileMenu}
          </>
        ) : (
          <>
            <Button color="inherit" style={{ color: selectedMenuItem === 'values' ? '#F46A35' : '#000' }} onClick={() => handleMenuItemClick('values')}>Nossos Valores</Button>
            <Button color="inherit" style={{ color: selectedMenuItem === 'aboutUs' ? '#F46A35' : '#000' }} onClick={() => handleMenuItemClick('aboutUs')}>Quem Somos</Button>
            <Button color="inherit" style={{ color: selectedMenuItem === 'pricing' ? '#F46A35' : '#000' }} onClick={() => handleMenuItemClick('pricing')}>Preços</Button>
            <Button color="inherit" style={{ color: selectedMenuItem === 'faq' ? '#F46A35' : '#000' }} onClick={() => handleMenuItemClick('faq')}>Dúvidas</Button>
            <Button color="inherit" style={{ color: selectedMenuItem === 'login' ? '#F46A35' : '#000' }} onClick={() => handleMenuItemClick('login')}>Entrar</Button>
            <Button variant="contained" color="primary" style={{ borderRadius: '20px', marginLeft: '10px' }} onClick={() => window.location.href = 'https://dashboard.spifex.com/signup'}>
              Cadastrar
            </Button>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
