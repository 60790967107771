import React, { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Start from './components/Start';
import Features from './components/Features';
import PricingTable from './components/PricingTable';
import Chatbot from './components/Chatbot';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';

const App = () => {
  const pricingRef = useRef(null);
  const topRef = useRef(null);

  const scrollToPricing = () => {
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Router>
      <div ref={topRef}>
        <Header scrollToPricing={scrollToPricing} scrollToTop={scrollToTop} />
        <Routes>
          <Route path="/" element={
            <>
              <Start />
              <Features />
              <div ref={pricingRef}>
                <PricingTable />
              </div>
              <Chatbot />
              <Footer />
            </>
          } />
          <Route path="/about" element={<AboutUs />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
