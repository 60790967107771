import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';

const features = [
  { title: 'Integração e Centralização', description: 'Centralizar todas as operações financeiras em uma única plataforma integrada e inteligente.' },
  { title: 'Inteligência Artificial', description: 'Tomar decisões estratégicas com base em dados concretos, antecipando cenários futuros com confiança.' },
  { title: 'Simplicidade e Intuitividade', description: 'Reduzir a curva de aprendizado e tornar a gestão acessível aos membros da equipe, independentemente de sua especialização.' },
  { title: 'Flexibilidade e Organização', description: 'Obter uma visão detalhada e personalizada das operações financeiras, facilitando o rastreamento e a análise de custos de forma segmentada e precisa.' },
  { title: 'Relatórios Personalizáveis', description: 'Com a AI, é possível obter relatórios e indicadores específicos para o negócio, permitindo uma compreensão clara do negócio.' },
  { title: 'Segmentação Interna', description: 'Destrinchamos todos os detalhes necessários para você entender profundamente as questões internas da sua empresa.' },
];

const Features = () => {
  return (
    <Box sx={{ py: { xs: 5, md: 10 }, bgcolor: '#f5f5f5', color: 'white' }}>
      <Container>
        <Typography 
          variant="h2" 
          component="h2" 
          textAlign="center" 
          fontWeight="bold" 
          color="black" 
          gutterBottom 
          sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }} // Responsividade para o título
        >
          Funcionalidades
        </Typography>
        <Typography 
          variant="body1" 
          component="p" 
          textAlign="center" 
          color="black" 
          mb={{ xs: 4, md: 8 }}
          sx={{ fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }} // Responsividade para o texto
        >
          Descubra por que nosso produto é a escolha ideal: máxima adaptabilidade, durabilidade incomparável, design intuitivo e inovação constante. Aproveite o suporte ao cliente de primeira classe e a precisão em cada detalhe.
        </Typography>
        <Grid container spacing={{ xs: 2, md: 4 }}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  bgcolor: '#F46A35',
                  borderRadius: '10px',
                  textAlign: 'center',
                  transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
                    bgcolor: '#D1401A',
                  },
                }}
              >
                <Box 
                  sx={{ 
                    p: 2, 
                    bgcolor: '#F46A35', 
                    borderRadius: '10px 10px 0px 0px', 
                    borderBottom: '2px solid rgba(255, 255, 255, 0.2)',
                    fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } // Responsividade para o título do feature
                  }}
                >
                  <Typography variant="h6" component="h3" fontWeight="bold" color="white">
                    {feature.title}
                  </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography 
                    variant="body1" 
                    component="p" 
                    color="white"
                    sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' } }} // Responsividade para a descrição do feature
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
