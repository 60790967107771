import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import tecnologiaImage from "../assets/tecnologia.jpg"; // Imagem para a seção "Futuro da Gestão Financeira"
import missaoImage from "../assets/missao.png"; // Imagem para a seção "Nossa Missão"
import fundador1 from "../assets/edgar.jpg"; // Imagem do primeiro fundador
import fundador2 from "../assets/marlon.jpeg"; // Imagem do segundo fundador
import fundador3 from "../assets/edgar.jpg"; // Imagem do terceiro fundador

const AboutUs = () => {
  return (
    <div className="container my-5">
      {/* About Us Section */}
      <section className="text-center mb-5 mt-20">
        <h1 className="text-4xl font-bold mb-4 text-[#F46A35] tracking-wider">
          Quem Somos
        </h1>
      </section>

      {/* Introduction Section */}
      <section
        className="text-center mb-5"
        style={{
          backgroundColor: "#f8f9fa",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
          Na Spifex, somos movidos pela visão de transformar a gestão financeira
        </h2>
        <p className="lead" style={{ color: "#555" }}>
          Nossa plataforma integrada é mais do que apenas uma ferramenta
          tecnológica; é uma solução completa que une o poder da inteligência
          artificial com uma interface intuitiva para revolucionar a maneira
          como as empresas administram suas finanças.
        </p>
      </section>

      {/* Our Mission Section */}
      <section className="mb-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              src={missaoImage}
              alt="Mission"
              className="img-fluid rounded shadow-lg"
              style={{ borderRadius: "15px" }}
            />
          </div>
          <div className="col-md-6">
            <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
              Nossa Missão
            </h2>
            <p style={{ color: "#555" }}>
              Nossa missão é capacitar empresas de todos os tamanhos a gerir
              seus recursos financeiros de forma mais eficaz, otimizando
              processos, economizando tempo e recursos, e promovendo uma tomada
              de decisão informada e estratégica. Entendemos os desafios
              complexos que as organizações enfrentam na gestão financeira, e é
              por isso que desenvolvemos uma solução que consolida diversas
              funções em um único ambiente. Acreditamos que uma gestão
              financeira eficiente é a base para o crescimento sustentável e o
              sucesso a longo prazo.
            </p>
          </div>
        </div>
      </section>

      {/* What Drives Us Section */}
      <section
        className="mb-5"
        style={{
          backgroundColor: "#f8f9fa",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
          O Que Nos Move
        </h2>
        <p style={{ color: "#555" }}>
          Identificamos os principais desafios enfrentados por empresas no
          ambiente financeiro e criamos a Spifex para resolvê-los de maneira
          inovadora e prática. Sabemos que a complexidade dos dados financeiros
          e a falta de ferramentas adequadas podem atrapalhar a eficiência e o
          crescimento. Por isso, nossa plataforma automatiza processos e
          facilita o acesso a informações essenciais, permitindo que você se
          concentre no que realmente importa: a estratégia e o crescimento do
          seu negócio.
        </p>
      </section>

      {/* Sustainability Commitment Section */}
      <section className="mb-5">
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <img
              src="/mnt/data/image.png"
              alt="Sustainability"
              className="img-fluid rounded shadow-lg"
              style={{ borderRadius: "15px" }}
            />
          </div>
          <div className="col-md-6 order-md-1">
            <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
              Nosso Compromisso com a Sustentabilidade
            </h2>
            <p style={{ color: "#555" }}>
              Acreditamos que uma boa gestão financeira não só fortalece as
              empresas, mas também cria oportunidades para impactar
              positivamente a sociedade e o meio ambiente. Com processos mais
              eficientes e maior transparência, as empresas podem redirecionar
              recursos para iniciativas de responsabilidade social e ambiental,
              promovendo um futuro mais sustentável.
            </p>
          </div>
        </div>
      </section>

      {/* Transparency and Collaboration Section */}
      <section
        className="mb-5"
        style={{
          backgroundColor: "#f8f9fa",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
          Transparência e Colaboração
        </h2>
        <p style={{ color: "#555" }}>
          Na Spifex, promovemos uma cultura de transparência e colaboração.
          Nossa plataforma oferece visibilidade total sobre a saúde financeira
          da sua empresa, permitindo que todos os departamentos e gestores
          estejam alinhados e informados. Isso não só facilita a gestão interna,
          mas também fortalece a responsabilidade e a confiança dentro da
          organização.
        </p>
      </section>

      {/* Future of Financial Management Section */}
      <section className="mb-5">
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              src={tecnologiaImage}
              alt="Future"
              className="img-fluid rounded shadow-lg"
              style={{ borderRadius: "15px" }}
            />
          </div>
          <div className="col-md-6">
            <h2 className="h2 font-weight-bold mb-4" style={{ color: "#333" }}>
              O Futuro da Gestão Financeira
            </h2>
            <p style={{ color: "#555" }}>
              Estamos comprometidos em continuar inovando e evoluindo nossa
              plataforma para atender às necessidades em constante mudança do
              mercado. Com a Spifex, você tem uma parceira que está sempre à
              frente, trazendo o futuro da gestão financeira para o presente.
            </p>
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section className="mb-5">
        <h2 className="h2 font-weight-bold mb-4 text-center" style={{ color: "#333" }}>
          Nossos Fundadores
        </h2>
        <div className="row text-center">
          <div className="col-md-4">
            <img
              src={fundador1}
              alt="Fundador 1"
              className="img-fluid rounded-circle mb-3 shadow-lg"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <h3 className="h5 font-weight-bold mb-1">Edgar Moraes</h3>
            <p style={{ color: "#555" }}>CEO & Co-fundador</p>
            <p style={{ color: "#777" }}>
             Edgar tem mais de 10 anos de experiência em gestão financeira e é
              o visionário por trás da Spifex.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src={fundador2}
              alt="Fundador 2"
              className="img-fluid rounded-circle mb-3 shadow-lg"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <h3 className="h5 font-weight-bold mb-1">Marlon Zotty</h3>
            <p style={{ color: "#555" }}>Desenvolvedor</p>
            <p style={{ color: "#777" }}>
              Marlon  lidera a equipe de tecnologia com uma vasta experiência em
              desenvolvimento de software e IA.
            </p>
          </div>
          <div className="col-md-4">
            <img
              src={fundador3}
              alt="Fundador 3"
              className="img-fluid rounded-circle mb-3 shadow-lg"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
            />
            <h3 className="h5 font-weight-bold mb-1">João Vitor</h3>
            <p style={{ color: "#555" }}>CFO & Co-fundador</p>
            <p style={{ color: "#777" }}>
             João vitor é o responsável por garantir a saúde financeira da Spifex e
              o sucesso de nossos clientes.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section
        className="text-center mb-5"
        style={{
          padding: "30px",
          background: "linear-gradient(135deg, #F46A35, #FF6B6B)",
          borderRadius: "15px",
          color: "#fff",
        }}
      >
        <h2 className="h2 font-weight-bold mb-4">Junte-se a nós</h2>
        <p>
          Descubra como a Spifex pode transformar a gestão financeira da sua
          empresa.
        </p>
        <button
          className="btn btn-light btn-lg mt-3"
          style={{ borderRadius: "25px", padding: "10px 30px" }}
        >
          Saiba Mais
        </button>
      </section>

      {/* Footer Section */}
      <footer className="bg-dark text-white py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h5 className="font-weight-bold">Spifex</h5>
              <p>123 Rua da Tecnologia, São Paulo, SP, Brasil</p>
              <p>+55 11 1234-5678</p>
              <p>contato@spifex.com</p>
            </div>
            <div className="col-md-4">
              <h5 className="font-weight-bold">Horário de Funcionamento</h5>
              <p>Seg - Sex: 9:00 - 18:00</p>
              <p>Sábado: 10:00 - 16:00</p>
              <p>Domingo: Fechado</p>
            </div>
            <div className="col-md-4">
              <h5 className="font-weight-bold">Conta</h5>
              <p>Minha Conta</p>
              <p>Histórico de Pedidos</p>
              <p>Política de Privacidade</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AboutUs;
